.btn {
    @extend .h4;
    color: #fff;
    display: inline-block;
    border-radius: .4em;
    box-shadow: 0 .5em 1em rgba(0, 0, 0, 0.15);
    background: $gradient-btn-400;
    padding: .6em 1.5em .5em;
    text-decoration: none;
    text-align: center;
    transition: all .2s ease-in-out;
    &:hover {
        text-decoration: underline;
    }
}