body > header {
    background-color: $color-purple;
    padding: 1em 0;

    .content {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
    }
    a.logo {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        img{
            height: 50px;
            width: auto;
        }
        img:last-child{
            margin-left: .25em;
        }
    }

    .links {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-end;
        .btn {
            & + .btn {
                margin-left: 1.0em;
            }
        }
    }

    @media screen and (max-width: $max-width-tablet-portrait) {
        .content {
            justify-content: center;
            .logo {
                margin-left: 2rem;
            }
        }
        .links {
            display: none;
        }
    }
}

body > header + nav {
    background-color: white;
    box-shadow: 0.5px 0.866px 15px rgba(0, 0, 0, 0.25);
    ul {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        list-style: none;
        a {
            padding: .5em 1em .45em;
            text-decoration: none;
            @extend .h4;
            font-weight: 400;
            &:hover {
                text-decoration: underline;
            }
            &.active {
                //color: $color-blue;
                text-decoration: underline;
            }
        }
    }
    padding: .5em 0 .45em;
}