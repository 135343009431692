//  C O L O R S
// purple
$color-purple-400: #3e0658;
$color-purple: $color-purple-400;

// blue
$color-blue-400: #0099e2;
$color-blue-700: #007bff;

$color-blue: $color-blue-400;

// gradients
$gradient-btn-400: linear-gradient(170deg, #27d9bb 0%, #0099e1 100%);
$gradient-btn-700: linear-gradient(170deg, #26c5a7 0%, #0086cd 100%);
$gradient-btn: $gradient-btn-400;


//  F O N T S
$font-family: 'PT Sans', sans-serif;

//  F O N T   S I Z E S
$font-size-phone: 14px;
$font-size-tablet-portrait: 15px;
$font-size-tablet-landscape: 15px;
$font-size-laptop: 16px;
$font-size-desktop: 17px;
$font-size-4k: calc(#{$font-size-desktop} * 2);
$font-size-8k: calc(#{$font-size-4k} * 2);

$font-size-h1: 3rem; /* 48 px on laptop */
$font-size-h2: 2.25rem; /* 36 px on laptop */
$font-size-h3: 1.5rem;     /* 24 px on laptop */
$font-size-h4: 1.125rem; /* 18 px on laptop */
$font-size-h5: 1rem;         /* 16 px on laptop */
$font-size-h6: .875rem; /* 14 px on laptop */




//   D E V I C E   W I D T H S
$gap: 1rem;

$max-width-phone: 600px;
$max-width-tablet-portrait: 900px;
$max-width-tablet-landscape: 1200px;
$max-width-laptop: 1500px;
$max-width-desktop: 3000px;
$max-width-4k: calc(#{$max-width-desktop} * 2);
$max-width-8k: calc(#{$max-width-4k} * 2);

$min-width-phone: 0px;
$min-width-tablet-portrait: calc(#{$max-width-phone} + 1px);
$min-width-tablet-landscape: calc(#{$max-width-tablet-portrait} + 1px);
$min-width-laptop: calc(#{$max-width-tablet-landscape} + 1px);
$min-width-desktop: calc(#{$max-width-laptop} + 1px);
$min-width-4k: calc(#{$max-width-desktop} + 1px);
$min-width-8k: calc(#{$max-width-4k} + 1px);

$width-content-phone: 100%;
$width-content-tablet-portrait: 100%;
$width-content-tablet-landscape: calc(#{$max-width-tablet-portrait} - 2* #{$gap});
$width-content-laptop: calc(#{$max-width-tablet-landscape} - 2* #{$gap});
$width-content-desktop: calc(#{$max-width-laptop} - 2* #{$gap} - 200px);
$width-content-4k: calc(#{$width-content-desktop} * 2);
$width-content-8k: calc(#{$width-content-4k} * 2);
