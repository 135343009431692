* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: $font-family;
}

html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;

    font-size: $font-size-phone;
    .content {
        margin: 0 auto;
        max-width: calc(100% - #{$gap} * 2);;
    }
    @media screen and (min-width: $min-width-tablet-portrait){
        font-size: $font-size-tablet-portrait;
    }
    @media screen and (min-width: $min-width-tablet-landscape){
        font-size: $font-size-tablet-landscape;
        .content {
            max-width: $width-content-tablet-landscape;
        }
    }
    @media screen and (min-width: $min-width-laptop){
        font-size: $font-size-laptop;
        .content {
            max-width: $width-content-laptop;
        }
    }
    @media screen and (min-width: $min-width-desktop){
        font-size: $font-size-desktop;
        .content {
            max-width: $width-content-desktop;
        }
    }
    @media screen and (min-width: $min-width-4k){
        font-size: $font-size-4k;
    }
    @media screen and (min-width: $min-width-8k){
        font-size: $font-size-8k;
    }

    width: 100vw;
    overflow-x: hidden;
}

button {
    font-size: 1rem;
}

a{
    cursor: pointer;
    display: block;
    color: $color-blue;
    text-decoration: none;
}

p a {
    display: inline;
    &:hover {
        text-decoration: underline;
    }
}

table{
    border-collapse: collapse;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    font-weight: 700;
    color: $color-purple;
    line-height: 1em;
}

h1, .h1 {
    font-size: $font-size-h1;
}
h2, .h2 {
    font-size: $font-size-h2;
}
h3, .h3 {
    font-size: $font-size-h3;
}
h4, .h4 {
    font-size: $font-size-h4;
}
h5, .h5 {
    font-size: $font-size-h5;
}
h6, .h6 {
    font-size: $font-size-h6;
}

.shadow-materialize {
    box-shadow: 0 2rem 6rem 0 rgba(31, 2, 89, 0.1);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    &:hover{
        box-shadow: 0 2rem 6rem rgba(31, 2, 89, 0.1), 0 1rem 2rem rgba(31, 2, 89, 0.1);
    }
}
