article {
    border-radius: .5rem;
    margin: 4rem 0;
    overflow: hidden;
    &:first-child {
        margin-top: 3rem;
    }
    flex-basis: 100%;
    width: 100%;
    header {
        img {
            width: 100%;
        }
        position: relative;
        .published-time {
            position: absolute;
            bottom: 0;
            right: 3em;
            font-weight: 700;
            color: #777;
            background-color: white;
            font-size: .8em;
            padding: .3em 1em .35em;
            border-top-left-radius: .5em;
            border-top-right-radius: .5em;
        }
    }
    @extend .shadow-materialize;
    main {
        padding: 1.5rem 2rem 1rem;
        h1, h2 {
            margin-bottom: .5em;
        }
        h1 {
            @extend .h2
        }
        a h2 {
            &:hover {
                text-decoration: underline;
            }
        }
        .tags {
            margin-top: 1.25rem;
            .fa {
                color: $color-blue;
                margin-right: .5em;
            }
            a {
                margin-right: .5em;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    footer {
        padding: 1rem 0 1.5rem;
        margin: 0 2rem;
        border-top: 1px solid #ddd;
        .authors {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: flex-start;
            h3 {
                flex-basis: 100%;
                @extend .h6;
                font-weight: 400;
                color: #878787;
                margin-left: .3em;
                margin-bottom: .5em;
            }
            .author {
                flex-basis: 50%;
                @media screen and (max-width: $max-width-phone) {
                    & + .author {
                        margin-top: 1em;
                    }
                    flex-basis: 100%;
                }
                &:nth-child(n + 3) {
                    margin-top: 1em;
                }
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                img {
                    width: 3rem;
                    border-radius: 50%;
                    border: 1px solid rgba(white, .5);
                    margin-right: 1rem;
                }
                .name {
                    color: $color-purple;
                    line-height: 1em;
                    display: block;
                }
                .position {
                    font-size: .9em;
                    line-height: .9em;
                    color: #777;
                    font-style: italic;
                }
                &:hover .name {
                    text-decoration: underline;
                }
            }
        }
    }

    .body {
        p, ul, ol {
            &:not(:last-child){
                margin-bottom: 1em;
            }
        }
        iframe {
            width: 100%;
            height: 100%;
        }
        ol, ul {
            padding-left: 2em;
        }
        h2 {
            @extend .h3;
        }
        h3 {
            @extend .h4;
        }
        h4 {
            @extend .h5;
        }
    }
}

.article-list-header {
    @extend article;
    h1 {
        .fa {
            font-size: .7em;
            margin-right: .5em;
        }
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
        &:last-child {
            margin-bottom: 0;
        }
    }
    main {
        padding-bottom: 1.5rem;
    }
    .total-items {
        padding-top: .25em;
        margin-left: 1em;
        font-size: .45em;
        font-weight: 400;
    }
}