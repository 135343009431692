body > .content {
    display: flex;
    flex-flow: row wrap;

    & > main {
        width: 100%;
        flex-basis: 100%;
        @media screen and (min-width: $min-width-laptop) {
            flex-basis: calc(100% - 300px - 2 * #{$gap});
        }

    }
    & > aside {
        display: none;
        @media screen and (min-width: $min-width-laptop) {
            display: flex;
            margin-left: calc(2 * #{$gap});
            flex-basis: calc(300px);
            flex-flow: column nowrap;
        }
    }
}