.pagination {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;
    font-size: 1.25rem;
    a, span {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: row nowrap;
        height: 1.8em;
        width: 1.8em;
        margin: 0 .25em;
        color: $color-purple;
        font-weight: 700;
        &.active {
            background-color: $color-purple;
            color: white;
            border-radius: .3em;
        }
        .fa {
            position: relative;
            top: -.05em;
            font-size: 1.25em;
        }
        &.disabled:not(.active) {
            color: #bfbfbf;
        }
    }
}