aside {
    & > * {
        border-radius: .5rem;
        box-shadow: 0 2rem 6rem 0 rgba(31, 2, 89, 0.1);
        margin: 1rem 0;
        width: 100%;
        padding: 1.5rem 2rem;
        h2 {
            @extend .h3;
            margin-bottom: .5em;
        }
        &:first-child {
            margin-top: 3rem;
        }
        @extend .shadow-materialize;
    }


}

#lewest-will-help-you {
    background: url("/assets/images/background-we-will-help-you.jpg");
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    padding-bottom: 10.5rem;
    h2 {
        text-align: center;
        img {
            display: inline;
            height: 1em;
        }
        margin-bottom: 1em;
    }
    ul {
        list-style: none;
        li {
            text-align: center;
            a {
                text-transform: uppercase;
                font-size: 1.25rem;
                width: 100%;
            }
            + li {
                margin-top: .5em;
            }
        }
    }
}

#tags-weight-map {
    a {
        margin-right: .5rem;
        line-height: 1em;
    }
}