body > footer {
    background-color: $color-purple;
    .content {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        img {
            height: auto;
            width: 200px;
            margin: 2rem 0;
        }
    }
}